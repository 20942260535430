import character from './character.jpeg';
import './App.css';

function App() {
  return (
    <div className="App">
      <main className="wrapper">

        <section className="header">
          <h1 className="title">
            Natura
          </h1>
          <p>Week 0 Report: </p>
          <p>18 December 2022</p>


          <ul class="breadcrumb">
            <li><a href="/">Natura</a></li>
            <li><a href="/devposts">Devblog Posts</a></li>
            <li><a href="/week0">18 December</a></li>
          </ul>
          

        </section>

        <section className="content">
        
          <h1>Zero to RPG</h1>
          <p>Devastating war.  Gigantic organomechanical machines.  Elemental magic.  “Natura” documents a veteran soldier’s desperate attempt to save his unit of raw recruits when their peacekeeping mission goes awry.</p>
          
        <div className="main_image_div">
          <img src={character} alt="Main Character Portrait" />
          <h3>Our as-yet-unnamed hero.</h3>
        </div>
          

          <p>A tile-based isometric tactical RPG inspired by classic titles like Final Fantasy Tactics and XCOM, the game will feature a multitude of classes, abilities, and equipment enabling a huge range of tactical options, and a deep storyline that draws the player into learning more about the world and characters.</p>
          <p>Our team for now consists of two people, and we’re both learning the skills we need to make this game a reality.  For this endeavor we’ve decided to use Godot, an open source game engine, and Asesprite, a pixel art studio.  We still need to figure out music and sound.</p>
          <p>Before we started working, we were hoping that by the end of 6 weeks we’d have at least a working prototype engine and map, and were brainstorming ways to get started — but it just so happens that a talented Godot developer has already created a basic isometric RPG engine, fully open source!  Incredible!  </p>
          <p>This engine features 2D sprites in a 3D rendered environment, which is perfect for what we need.  It’s even programmed to convert specially-formatted maps from Blender into usable tilemaps.  It does however have some idiosyncrasies that will require a careful refactoring of the code.  For instance, the action loop only supports attacking — we’ll need to add in abilities, which will be much more complicated than it sounds.</p>
          <p>The game will also need a fully updated UI, a formation screen, a map screen, a shop screen and recruit screen, a save and load system, a tutorial mode, and a lore dictionary.  And there’s the gameplay itself: we’ll need classes, stats, abilities, a progression system for both character level and class level, equipment, and tons and tons of animated sprites and particle effects.  And then there will be the story and cutscenes!</p>
          <p>Our goal now for these first six weeks is to complete a playable sample scenario, the first battle in our game’s story.  For that we’ll need at least 5 animated sprites, 5 character portraits, a mesh map of the battlefield and textures for the mesh.  We will also need 4 classes, their stats and equipment, and a few working abilities.  We’ll need the battle UI, and of course a working, updated game engine.</p>
          <p>Over these six weeks we’ll update you on our progress in both coding and art, as we continue to improve both our skills and our game.  Check out this blog and our short weekly videos for a peek at our progress.</p>

       
        </section>

      </main>

      <footer>
        <h3>art on this page was generated using <a href="https://lexica.art" target="blank">{ }Lexica{ }</a>.</h3>
        <h4>updated 22 December 2022</h4>
      </footer>

    </div>

  );
}

export default App;
