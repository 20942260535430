import game from './game.png';
import sprite from './peacekeeper_sprite.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <main className="wrapper">

        <section className="header">
          <h1 className="title">
            Natura
          </h1>
          <p>Week 1 Report: </p> 
          <p>25 December 2022</p>

          <ul class="breadcrumb">
            <li><a href="/">Natura</a></li>
            <li><a href="/devposts">Devblog Posts</a></li>
            <li><a href="/week1">25 December</a></li>
          </ul>


        </section>

        <section className="content">
        
          <h1>Working with Godot</h1>
                    
        <div className="main_image_div">
          <img src={game} alt="Image of vectors on the test battle map with placeholder pawns." />
          <h3 className="subtitle">Vectors on the test battle map with placeholder pawns.</h3>
        </div>

        <p>The Tactics RPG engine is not only an extremely useful tool, it hss been a great way to get familiar with Godot and 3D space in game design.  It has a pathfinding algorithm, camera logic, a logic loop to handle moving and attacking, simple AI for enemies, sample sprites, and something we haven't used yet but which will be very powerful later: a script for converting battle maps imported from Blender into usable tile maps.</p>

         <div className="main_image_div">
          <img src={sprite} alt="Draft sprite for our main character." />
          <h3 className="subtitle">We're learning how to create our own sprites (using Aseprite) as well!</h3>
        </div>  

        <p>The engine is functional, but otherwise barebones.  Some of the issues we're sorting out: </p>
          <ul>
            <li>There isn't a sophisticated turn ordering system.  The player team goes, and then the enemy team goes.</li>

            <li>Pawns (the characters controlled by the player and by the computer) are placed and configured manually before the game launches. There is no mechanism to spawn pawns onto the battlefield, and no way to dynamically change the characteristics of those pawns (if we wanted to let the player pick which characters to deploy, for example, and to persist characters from one battle to the next). </li>

            <li>Data organization is fragmented.  Rather than being organized all in one place using dictionaries, every character stat has its own individual look-up table.</li>

            <li>Characters have no abilities, theu cam only attack and move.</li>

            <li>The UI is very simple.  There are no character cards, the character's name and HP simply floats overhead  There are no animations or damage numbers during an attack.</li>

            <li>There is no level-up system. There is no equipment. There is no formation screen, no shop, and no overworld map.</li>
          </ul>

        <p>Since we're still learning GDScript and what is possible with the engine, we approached things in parts and learned as we went.  The first thing we did was randomize the turn ordering system; this in turn will eventually be replaced with something more advanced, when charge attacks and character speed are introduced. </p>

        <p>We tinkered with the UI elements, but we weren't familiar enough with the system yet to really get that working how we wanted it.  We have the impression that Godot's UI tools will work like a dream - once we really understand how to use them.  For now, our focus is on testing the basic gameplay, which means we just need to get the UI into a workable state.</p>

        <p>After doing more Godot tutorials, we took some time to plan how we wanted to organize the game's data. Godot can make use of singletons to store global variables, and so we decided to create an Encyclopedia scene that will contain all class information, every ability, and every piece of equipment, to be referenced during battle, in the formation screen, and in shops.</p>

        <p>We'll also create a Party scene that contains all of the party member information, such as their names, classes, levels, stats, and equipment - this will come in handy later when it's time to create the Save Game system.  These features will take shape over the next few weeks.</p>

        <p>But first we needed to address the Pawn problem.  Pawns need to be dynamically placed, to allow mid-battle spawns, but more importantly to let the player pass their party members' data to the battlefield scene.  We wrestled with this problem for awhile before discovering the correct method to set the incoming pawn's position to the position of a map tile.  With that, it became possible to spawn pawns wherever we want them, and with the new data organization, to pass them any character data they need at the moment of spawning. </p>

        <p>We organized the class data more to our liking, then started creating the foundation for the ability system.</p>

        <p>There will eventually be “charge attacks” - abilities that take time to complete instead of executing instantly.  The turn ordering system and action loop will need to accommodate this.  For now, we just wanted to give the player options other than attacking.  This required refactoring all of the “attack” functions into “act” functions, and treating “Attack” as a universal class ability, rather than as a separate action.  When a player selects an ability, its data (checks, range, area of effect, type, and formula) gets loaded from the Encyclopedia singleton.</p>

        <p>While the ability system now works in basic form, the next step will be adding in special “tile patterns” to Abilities to give them areas of effect.  There will also be a “confirm” stage to the action loop - where the player sees the area of effect of their chosen ability after they've picked their target, and is given the option of confirming or canceling the ability.</p>

        <p>You can try our extremely early and basic demo  <a href="https://ice-wizardry.itch.io/natura" target="_blank">here</a>! (The password is natura-game.)  Expect many things to change over the next few weeks. If you've never played an isometric TRPG before, this is the basic idea. Once the game starts you'll need to click on the window to give commands.</p>

        <p>Next up:</p>
            <ul> 
              <li>Ability confirms and area of effect</li>
              <li>More sprites and map textures</li>
              <li>Stats and leveling up</li>
              <li>Equipment</li>
              <li>Formation screen and placement screen</li>
              <li>The turn-ordering system and charge abilities</li>
          </ul>
          
            </section>

          </main>

      <footer>
        <h4>updated 25 December 2022</h4>
      </footer>

    </div>

  );          
        
}

export default App;
