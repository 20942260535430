import React from 'react';
import './App.css';


function App() {

  return (
    <div className="App">
      <main className="grid-area">

        <section className="header">

        
          <h1 className="title">
            Natura
          </h1>

          <p>Meet the Team</p>

          <ul class="breadcrumb">
                <li><a href="/">Natura</a></li>
                <li><a href="/devposts">Devblog Posts</a></li>
                <li><a href="/teaminfo">About Us</a></li>
          </ul> 
          
        </section>
       

        <section className="blogposts" id="blogposts">
     
        <ul>

          <li>
              <div className="item-info">
                <p className="subtitle">
                  Cactus is a game designer, Solidity developer, and spaghetti code untangler.            
                </p>
              </div>
            </li>

            <li>
              <div className="item-info">
                <p className="subtitle">
                  Frost is a frontend engineer, Solidity dabbler, and aspiring digital artist.           
                </p>
              </div>
            </li>

        </ul>
        </section>

      </main>

      <footer>
        <h4>updated 2 January 2023</h4>
      </footer>

    </div>
  );
}

export default App;