import './App.css';
import runner from './assets/runner_guy.gif';
import hero1 from './assets/hero1.png';
import hero2 from './assets/hero2.png';
import hero3 from './assets/hero3.png';
import formation1 from './assets/formation1.png';
import formation2 from './assets/formation2.png';
import formation3 from './assets/formation3.png';
import formation4 from './assets/formation4.png'; 

function App() {
  return (
    <div className="App">
      <main className="wrapper">

        <section className="header">
          <h1 className="title">
            Natura
          </h1>
          <p>Week 4 Report: </p> 
          <p>17 January 2023</p>

          <ul class="breadcrumb">
            <li><a href="/">Natura</a></li>
            <li><a href="/devposts">Devblog Posts</a></li>
            <li><a href="/week4">17 January</a></li>
          </ul>


        </section>

        <section className="content">
        
          <h1>Stats, Time, and Sprites</h1>

          <h2>Stats & Time</h2>
    
          <p>Pictured below is an early version of the formation screen, where data about the player's party is organized.  Here the player can change each character's class, abilities, and equipment.  The game follows a dual progression system, where characters can gain experience and level up, while also gaining "ability points" in their chosen class.  After gaining enough ability points in a given class, a character can unlock the next class in the progression tree.  AP is also used to learn class abilities, such as the Technician's various device proficiencies, and the Peacekeeper's general purpose skills.</p>    
            
            
        <div className="week2_images_wrapper">

            <div className="week2_test_image_div">
                <img src={formation1} alt="Image of in-game water effects." />
                <h3 className="subtitle">Select a party member to customize.</h3>
            </div> 

            <div className="week2_test_image_div">
                <img src={formation2} alt="Image of in-game fire effects." />
                <h3 className="subtitle">Change Class screen</h3>
            </div>

        </div>
        
        <div className="week2_images_wrapper">

            <div className="week2_test_image_div">
              <img src={formation3} alt="Image of in-game water effects." />
              <h3 className="subtitle">Learn Abilities screen</h3>
            </div> 
    
            <div className="week2_test_image_div">
              <img src={formation4} alt="Image of in-game fire effects." />
              <h3 className="subtitle">Change Equipment screen</h3>
            </div>

        </div>



        <p>Whenever a character levels up, they gain a bonus to their stats based on their current class.  Peacekeepers for example gain more HP and physical attack than Technicians when they level up, while Technicians gain more EP and energy attack.</p>

        <p>Stats like damage reduction, evasion, and speed will all depend on the character's class and the weight of their equipment; for example, characters in light armor will be faster than characters in heavy armor.</p>

        <p>The Speed stat is integral to the order of character actions during battles.  Eighteen years ago, a group of fans painstakingly analyzed and documented Final Fantasy Tactics' mechanics and math.  It is a truly fascinating work that gives insight into the turn ordering system from which we draw inspiration.</p>

        <p>For our game, the flow of time can be imagined in terms of rounds.  Each round consists of a sequence of checks and events that must be executed before progressing to the next round.</p>

        <p>The first event increments the time counter of all on-going effects, such as status effects or environmental effects.</p>

        <p>The second event increments the time counter of all on-going charge attacks - namely Energy attacks that don't have an instantaneous execution.</p>

        <p>The third event executes charge attacks that have reached the end of their time counter.  All ready charge attacks are executed in order before progressing to the next event.</p>

        <p>The fourth event increments every character's place in the turn order by adding their Speed score to their time score.</p>

        <p>The fifth event executes the turns of all characters who have passed their time score's active threshold.  All characters who have passed this threshold may take their turns in order.  Their time scores are then reset.</p>

        <p>If there are no characters beyond the active threshold, or if all characters beyond their threshold have acted, the round ends and the next begins.</p>

        <p>Because all gameplay takes place within an active loop, there will eventually be a marriage between this turn ordering system and the action sequence, where animations and sounds are played and the camera moves between actor and target.  As alluded to in the previous blog post, each ability will need to have its own timing and logic passed into the loop whenever it gets called.  We'll expand on this more in our next post.</p>


            <h2>Sprites</h2>
    
            <p>As mentioned in an earlier post, the usual pixel size of our in-game sprites is 128x128.  The early sprites were drawn on a 32x32 canvas because the minute detail on the larger canvas wasn’t visible in-game.  While it was fun to sketch and refine that way, it was also time-consuming.</p>

            <p>Currently, we’re drawing on a canvas that’s 48x48 and then scooting the sprite into a corner and trimming back to 32x32 before scaling up to 128x128.  This provides some extra space to work on the isometric proportions.</p>

            <p>Our hero was one of the first sprites we created, and his numerous iterations reflect part of our sprite journey.</p>

            <div className="main_image_div">
                <img src={hero1} alt="Image of our early hero sprites." />
                <h3 className="subtitle">Our initial hero sprites from Squatty Guy to Football Head to Flat Stanley.</h3>
            </div> 

            <p>One of these stood out as our favorite, so we worked to shift him from being front-on-slightly angled to having more isometric proportions.</p>

            <div className="main_image_div">
                <img src={hero2} alt="Our attempts at isometric sprites." />
                <h3 className="subtitle">Subtle differences as our hero shifted to a more isometric perspective.</h3>
            </div> 

            <p>We thought we had it, but when we dropped him back in-game, we realized he’d reacquired his football head so we went back to Aseprite for more tinkering.  He ended up being 48x32, which scales to 144x96 for his in-game version.</p>

            <div className="main_image_div">
                <img src={hero3} alt="Image of in-game fire effects." />
                <h3 className="subtitle">After a litle more tinkering, we arrived at the hero (and base character model) that we like a lot.</h3>
            </div> 

            <p>Our next step is adding the basic walking animation to the game, but at this point in development, there’s nothing ‘basic’ about this task. 🙂  </p>

            <p>Eventually, we'll need to figure out the code & logic for the Godot part of animation.  First, we need to make a little sprite with the structural constrictions of pixels walk naturally and purposefully.  This will be a wee bit different from animating purposeful walking in Procreate (which isn’t really ‘basic’ either).</p>
            
            <p>We're very excited about our side-view running sprite!  Running felt more natural to animate than walking purposefully, so we went with that initially.  Our next steps are to animate an isometric sprite model, and then to style it as our hero, and then to make him walk in-game.</p>  

            <div className="main_image_div">
                <img className="runner_guy" src={runner} alt="Image of in-game fire effects." />
                <h3 className="subtitle">Running animation viewed from the side!</h3>
            </div> 

            <p>So begins Week 5 of Nights & Weekends! LFG! </p>
    
        </section>
        <div className="spacer">
        </div>

      </main>

      <footer>
        <h4>updated 17 January 2023</h4>
      </footer>

    </div>

  );          
        
}

export default App;
