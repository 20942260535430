import week0 from './week0.jpeg';
import week1 from './week1.jpeg';
import week2 from './week2.jpeg';
import finleys from './assets/finleys.JPG';
import trust from './assets/trust.JPG';
import stars from './assets/stars.JPG';
import bubbles from './assets/bubbles.JPG';


import './App.css';

import React from 'react';


function App() {

  return (
    <div className="App">
      <main className="grid-area">

        <section className="header">
          
          <h1 className="title">
            Alena Elwood
          </h1>
          
        </section>

        <section className="demo">
      

          <h2>Dive in!</h2>
          <h3>Focus on where you're going, rather than where you've been. (Eddie Finley) 🏄🏼‍♂️ 🐾 </h3>
          <h3>Recognize it, acknowledge it, and let it go if you can. (Jase Boudreaux) 🎨 🍣 </h3>
          <h3>Sometimes, the reality of a thing is far more satisfying than the imagining of it.  (JT Slade) 🫧✨</h3>
          <h3>Books 4 & 5 of <i>The Dive Shop</i> series are coming in late fall 2024. 🤿 🌊</h3>

          
            <a href="https://www.amazon.com/stores/Alena-Elwood/author/B0CPQYVL1M" target="blank">Amazon Author Page</a>


        </section>

        <section className="choices">
     
        <ul>

        <li>
              <figure>
                <img src={finleys} alt="The Finleys book cover" />
              </figure>

              <div className="item-info">
                <p>
                  Meet Eddie Finley and his family in this in this novel about family, self-discovery, healing, and fresh, new beginnings.            
                </p>
              <a href="https://a.co/d/hsDcNpi" target="blank">Read on Kindle</a>
              </div>
            </li>

          <li>
              <figure>
                <img src={trust} alt="Trust book cover"/>
                
              </figure>
              <div className="item-info">
                <p>
                  Fall in love with Jase & Laini in this steamy, dual-perspective, friends-to-lovers romance.             
                </p>

                <p>
                  Book 1 in <i>The Dive Shop</i> series.
                </p>
              <a href="https://a.co/d/8acWZ5s" target="blank">Read on Kindle</a>
              </div>
            </li>

           <li>
              <figure>
                <img src={stars} alt="Seeing Stars book cover"/>
                
              </figure>
              <div className="item-info">
                <p>
                  JT is all about conflict, and has a penchant for meaningless encounters. Morgana is all about peace, and is dreaming of a man who wants to build a family.  Sparks fly when these opposites meet.           
                </p>

                <p>
                  Book 2 in <i>The Dive Shop</i> series.
                </p>
                
              <a href="https://a.co/d/aubyiSA" target="blank">Read on Kindle</a>
              </div>
            </li>


          <li>
              <figure>
                <img src={bubbles} alt="Beneath the Bubbles book cover"/>
                
              </figure>
              <div className="item-info">
                <p>
                  Life has a funny way of upending the best imagined scenarios. Will JT and Morgana weather the storm, or will external forces and hidden truths make them rethink their passionate connection?           
                </p>

                <p>
                  Book 3 in <i>The Dive Shop</i> series.
                </p>
                
              <a href="https://a.co/d/1LePw5H" target="blank">Read on Kindle</a>
              </div>
            </li>


          

        </ul>
        </section>

        

      </main>

      <footer>
        
        <h3>Connect with Alena via <a href="https://www.instagram.com/alena.elwood/" target="blank">{ }Instagram{ }</a>, <a href="https://www.facebook.com/profile.php?id=61555820701978" target="blank">{ }Facebook{ }</a> or <a href="https://www.linkedin.com/in/alena-elwood/" target="blank">{ }LinkedIn{ }</a>.</h3>
    
        <h3>art and photography by alena elwood</h3>
        <h4>updated 29 September 2024</h4>
      </footer>

    </div>
  );
}

export default App;
