import pic from './week2.png';

import pk1 from './assets/pk_detailed.png';
import pk2 from './assets/pk_simple.png';
import pk3 from './assets/pk_together.png';

import tech1 from './assets/tech_detailed.png';
import tech2 from './assets/tech_profile.jpeg';

import test1 from './assets/sprite_test.png';
import test2 from './assets/battle_test.png';

import './App.css';

function App() {
    return (
        <div className="App">
          <main className="wrapper">
    
            <section className="header">
              <h1 className="title">
                Natura
              </h1>
              <p>Week 2 Report:</p>
              <p>1 January 2023</p>

              <ul class="breadcrumb">
                <li><a href="/">Natura</a></li>
                <li><a href="/devposts">Devblog Posts</a></li>
                <li><a href="/week2">1 January</a></li>
              </ul>
          
            </section>
    
            <section className="content">
            
              <h1>The State of the Art</h1>


              <p>As we prepare for the public launch of Natura, we’re reorganizing and updating our site, and thinking about which of our prototype sprites will be debuting with our launch. </p> 

              <p>We’ve had some funny sprite moments during testing!  One of our robots ended up looking a lot like the back of an elephant (although the metallic shading came out pretty well).  The sprite thrown together for height-testing is actually a little…endearing.  We might clean him up and find a spot for him.</p>

              <p>The pixel size of our in-game sprites is 128x128.  Drawing on a 128x128 canvas was resulting in too many colors on the sprites, and too much minute detail that wasn’t visible in-game so we switched to 64x64.  While it’s easier to control shape and shading on the smaller canvas and then clean the sprites up after scaling, this, too, was presenting too many options for details.  For now, we’re back to experimenting with basic sprites drawn on a 32x32 canvas and then scaled when complete.</p>

              <div className="week2_images_wrapper">

                  <div className="week2_pk_image_div">
                      <img src={pk2} alt="Peacekeeper drawn at 64x64 pixels." />
                  </div>

                  <div className="week2_pk_image_div">
                      <img src={pk3} alt="Both prototype Peacekeepers together in-game." />
                  </div>

                  <div className="week2_pk_image_div">
                      <img src={pk1} alt="Peacekeeper drawn at 32x32 pixels." />
                  </div>

              </div>

              <h3 className="week2_h3">Peacekeeper sprite at 64x64 and 32x32 + shown together in-game.</h3>
              
    
              <p>We’re using Lexica to generate character portraits.  Which came first…the portrait or the sprite?  It’s a tossup.  Some of our portraits were from earlier experiments with Lexica and subsequently inspired corresponding sprites, while other portraits were generated using prompts describing sprites we created.</p>

              <div className="week2_images_wrapper">

                <div className="week2_tech_image_div">
                    <img src={tech2} alt="Technician portrait generated using Lexica." />
                </div>

                <div className="week2_tech_image_div">
                    <img src={tech1} alt="Prototype Technician (drawn at 64x64 pixels)." />
                </div>

              </div>

              <h3 className="week2_h3">Technician portrait generated by Lexica + prototype sprite created at 64x64.</h3>


              <p>As we get more adept with creating pixel art, we’re discovering our style, and that is having an impact on the overall aesthetic of Natura.  The game will still have a gritty feel, but it’s a little cozy, too.  Cozy grit.</p>

              <p>Heading into the coming week, we have a bit of drawing to do!  The standard male and female Peacekeepers need rebranding.  They were looking a little too much like vacationers who got lost hiking when they need to be a little more…ambassadorial?  Ostentatious and intimidating?  Or something like that.  The female version (pictured above) has potential but still needs some work.  They’ll continue to take shape on the canvas.</p>  

              <p>We’re happy with the male Technician (pictured above) but need to redraw him on the 32x32 pixel canvas, and we need to create the female Technician.  The protagonist Peacekeeper will need some updating eventually, but for now, his sprite is good.  Our various protobots (and a couple of interesting antagonists) are under construction and will be appearing in-game soon.</p>

              <p>These screenshots show us experimenting with new sprites, different sprite sizes, a new map, and AI-generated map textures.  The UI has been tightened with confirms on both actions and moving, and character cards now appear whenever the player mouses over a pawn.</p>


              <div className="week2_images_wrapper">

                <div className="week2_test_image_div">
                    <img src={test1} alt="Character sprites shown in-game." />
                </div>

                <div className="week2_test_image_div">
                    <img src={test2} alt="Characters + several test robots." />
                </div>


              </div>

              <h3 className="week2_h3">Testing sprites + textures.</h3>

              <p>Unseen here (but which will be shown in more detail later) are the equipment, stat, level-up, and party systems, which lay the foundation for progression in the game.  Next up is creating the party formation screen, where these systems will be more visible to the player.  More on all this next week!</p>


           
            </section>
    
          </main>
    
          <footer>
            <h4>updated 1 January 2023</h4>
          </footer>
    
        </div>
    
      );          
        
}

export default App;
