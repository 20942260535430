import './App.css';
import fire from './assets/fire.png';
import water from './assets/water.png';

function App() {
  return (
    <div className="App">
      <main className="wrapper">

        <section className="header">
          <h1 className="title">
            Natura
          </h1>
          <p>Week 3 Report: </p> 
          <p>10 January 2023</p>

          <ul class="breadcrumb">
            <li><a href="/">Natura</a></li>
            <li><a href="/devposts">Devblog Posts</a></li>
            <li><a href="/week3">10 January</a></li>
          </ul>


        </section>

        <section className="content">
        
          <h1>Path to Demo Day</h1>
    
            <p>For graphical effects, Godot has a built-in particle generator that spews polygons at customizable rates and spreads.  Here are some examples of fire we played around with this week:</p>
    
            <div className="main_image_div">
              <img src={fire} alt="Image of in-game fire effects." />
              <h3 className="subtitle">In-game fire effects using particles.</h3>
            </div> 

            <p>Much of this week was spent making plans for demo day.  We’d like to offer a new map and several classes to play, like the Enforcer and Grenadier.  The map will need textures, the class sprites will need animations, and each class will need multiple abilities and particle animations for each ability.</p>

            <p>We already designed the ability loop to handle general logic like the range of an ability, its attack area, and the type of move it is.  But as development has continued, it’s become clear that we’ll need to call specific logic for abilities with more complex behavior.  The plan is to have a singleton full of logic-containing functions named after their associated ability, which will be called whenever those abilities are used.  We also created a general particle animation+sound player, to be instantiated whenever needed (which will be frequently).</p>
    
            <div className="main_image_div">
              <img src={water} alt="Image of in-game water effects." />
              <h3 className="subtitle">In-game water effects.</h3>
            </div> 

            <p>We continued experimenting with sprite sizes, degrees of detail, and base sprite design.  We did some sketching and animation in Procreate to get a better feel for how to create realistic movement in a minimalist way.  One of our next-steps is to sketch and animate using Procreate’s isometric grid.  Our pixel sprites are evolving into a style we like, but we need to freely explore some of our ideas before transferring them into the structure of pixel art.</p>

            <p>Next week we should have further updated sprites as well as new class sprites, updated and expanded abilities, and the beginnings of the demo map.</p>
    
    
    
        </section>

      </main>

      <footer>
        <h4>updated 10 January 2023</h4>
      </footer>

    </div>

  );          
        
}

export default App;
