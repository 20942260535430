import './App.css';

import React from 'react';
import { Route, Routes, BrowserRouter, Link } from 'react-router-dom';


function App() {

  return (
    <div className="App">
      <main className="grid-area">

        <section className="header">

        
          <h1 className="title">
            Natura
          </h1>

          <p>Devblog Posts</p>

          <ul class="breadcrumb">
            <li><a href="/">Natura</a></li>
            <li><a href="/devposts">Devblog Posts</a></li>
          </ul>

          
        </section>


        <section className="blogposts" id="blogposts">
     
        <ul>
    
           <li>
              <div className="item-info">
                <p>17 January 2023</p>
                <h3>Stats, Time, and Sprites</h3>
                <a href="/week4">Week 4 Progress</a>
              </div>
            </li>
    
            <li>
              <div className="item-info">
                <p>10 January 2023</p>
                <h3>Path to Demo Day</h3>
                <a href="/week3">Week 3 Progress</a>
              </div>
            </li>

            <li>
              <div className="item-info">
                <p>1 January 2023</p>
                <h3>The State of the Art</h3>

              <a href="/week2">Week 2 Progress</a>
              </div>
            </li>

            <li>
              <div className="item-info">
                <p>25 December 2022</p>
                <h3>Working with Godot</h3>

              <a href="/week1">Week 1 Progress</a>
              </div>
            </li>

            <li>
              <div className="item-info">
                <p>18 December 2022</p>
                <h3>Zero to RPG</h3>
              <a href="/week0">Week 0 Progress</a>
              </div>
            </li>

        </ul>
        </section>

        <section className="about">
          <h3><a href="/teaminfo">Meet the Team</a></h3>
        </section>

      </main>

      <footer>
        <h4>updated 17 January 2023</h4>
      </footer>

    </div>
  );
}

export default App;
