import './App.css';

import React from 'react';
import { Route, Routes, BrowserRouter, Link } from 'react-router-dom';



function App() {

  return (
    <div className="App">
      <main className="grid-area">

        <section className="header">

        
          <h1 className="title">
            Natura
          </h1>

          <p>Game Overview</p>
          <p>Additional Content Coming Soon!</p>

          <ul class="breadcrumb">
                <li><a href="/">Natura</a></li>
                <li><a href="/gameinfo">About Natura</a></li>
          </ul>  
          
        </section>
       

        <section className="blogposts" id="blogposts">
     
        <ul>

            <li>
              <div className="item-info">
                <p className="subtitle">
                  Discover a world where Energy is harnessed and controlled by the mind, Technicians & Manipulars use gadgets to enhance their Energy, and intrigue abounds.           
                </p>
              </div>
            </li>

            <li>
              <div className="item-info">
                <p className="subtitle">
                  Inspired by classic tactical rpgs like Final Fantasy Tactics & XCOM, Natura is built with Godot, and uses assets created in Blender, Aseprite, and Lexia.             
                </p>
              </div>
            </li>

        </ul>
        </section>

        

      </main>

      <footer>
        <h4>updated 29 January 2023</h4>
      </footer>

    </div>
  );
}

export default App;
