import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';

import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Week0 from './week0';
import Week1 from './week1';
import Week2 from './week2';
import Week3 from './week3';
import Week4 from './week4';

import Devposts from './devposts';
import GameInfo from './gameinfo';
import TeamInfo from './teaminfo';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

<div className="App-header">

<BrowserRouter>
  <Routes>
    <Route path="/" element={<App />} />

    <Route path="/devposts" element={<Devposts/>} />
    <Route path="/gameinfo" element={<GameInfo/>} />
    <Route path="/teaminfo" element={<TeamInfo/>} />

    <Route path="/week0" element={<Week0/>} />
    <Route path="/week1" element={<Week1/>} />
    <Route path="/week2" element={<Week2/>} />
    <Route path="/week3" element={<Week3/>} />
    <Route path="/week4" element={<Week4/>} />


  </Routes>
</BrowserRouter>


</div>

   
  </React.StrictMode>
);


